import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { PartnersListTabsEnum } from '../../enums/partners-list-tabs.enum';

export const PartnerListTabs: TabDefinitionModel[] = [
  {
    label: 'TAB.ACTIVE',
    tabName: PartnersListTabsEnum.ACTIVE,
    link: `../../${PartnersListTabsEnum.ACTIVE}/1`,
    icon: 'bulb',
    count: 0,
  },
  {
    label: 'TAB.INACTIVE',
    tabName: PartnersListTabsEnum.INACTIVE,
    link: `../../${PartnersListTabsEnum.INACTIVE}/1`,
    icon: 'bulb-off',
    hideCount: true
  },
];

export function generateTableColumns(
  status?: PartnersListTabsEnum
): TableColumnModelExtended[] {
  const tableColumns: TableColumnModelExtended[] = [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center cursor-pointer',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      customizable: true,
      headerClass: 'center',
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.CUSTOMER_ID',
      prop: 'runpleId',
      width: 128,
      maxWidth: 128,
      sortable: true,
      customizable: true,
    },
    {
      cellTemplate: 'withStatusLabel',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.STATUS',
      prop: 'status',
      sortable: true,
      customizable: true,
      cellClass: 'center cursor-pointer',
      width: 124,
      maxWidth: 124,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.LEGAL_NAME',
      prop: 'name',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.VAT_NUMBER',
      prop: 'vatNumber',
      sortable: true,
      customizable: true,
      width: 155,
      maxWidth: 155,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.CONTACT_NAME',
      prop: 'contactName',
      sortable: true,
      customizable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.EMAIL',
      prop: 'email',
      cellClass: 'cursor-pointer',
      sortable: true,
      customizable: true,
      width: 255,
      maxWidth: 255,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.PHONE_NUMBER',
      prop: 'phone',
      cellClass: 'cursor-pointer',
      sortable: true,
      customizable: true,
      width: 155,
      maxWidth: 155,
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.UPDATED',
      prop: 'updatedAt',
      cellClass: 'cursor-pointer',
      width: 147,
      customizable: true,
      showWithStatuses: [PartnersListTabsEnum.ACTIVE],
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DEACTIVATED',
      prop: 'deactivatedAt',
      cellClass: 'cursor-pointer',
      width: 147,
      customizable: true,
      showWithStatuses: [PartnersListTabsEnum.INACTIVE],
    },
  ].filter((col: TableColumnModelExtended) => {
    if (col.showWithStatuses) {
      return col.showWithStatuses.find(
        (statusColumn) => status === statusColumn
      );
    }
    return true;
  });
  return tableColumns;
}
