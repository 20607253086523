import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { PartnerAliasFormComponent } from './partner-alias-form.component';
import { RnplCommonModule } from 'common/src/modules';


@NgModule({
  declarations: [
    PartnerAliasFormComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    // UIComponentsModule,
    // PartnersRoutingModule,
    TranslateModule
  ],
  exports: [
    PartnerAliasFormComponent
  ],
})
export class PartnerAliasFormModule { }
