import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rnpl-partner-alias-form',
  templateUrl: './partner-alias-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerAliasFormComponent {
  @Input() aliases: {id: string, alias: string}[] = [];
  @Input() aliasKey: 'bank' | 'card';
  @Input() isReadonly: boolean = true;
  @Input() aliasInputLabel: string = '';

  @Output('onAliasAdd') onAliasAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output('onAliasDelete') onAliasDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output('onAliasUpdate') onAliasUpdate: EventEmitter<{ alias: string, aliasId: string }> = new EventEmitter<{ alias: string, aliasId: string }>();

  public addAlias(): void {
    this.onAliasAdd.emit();
  }

  public updateAlias(alias: string, aliasId: string): void {
    this.onAliasUpdate.emit({alias, aliasId});
  }

  public deleteAlias(aliasId: string): void {
    this.onAliasDelete.emit(aliasId);
  }

}
