import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { OpenBankingApiService } from '../services/open-banking-api.service';

@Injectable()
export class OpenBankingEnabledResolver
  implements Resolve<boolean> {
  constructor(
    private openBankingApiService: OpenBankingApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return of(true)
    // return this.openBankingApiService.getOpenBankingEnabled();
    return this.openBankingApiService.getOpenBankingEnabled();
  }
}
